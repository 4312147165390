<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout"

export default {
    name: 'App',
    computed: {
        layout() {
            return this.$route.meta.layout || DefaultLayout
        }
    }
}
</script>

<style lang="scss">
@import "assets/styles/app";
</style>
