<template>
    <div class="default-layout">
        <section class="section hero is-fullheight">
            <div class="hero-body">
                <router-view/>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "DefaultLayout"
}
</script>

<style scoped>

</style>
