<template>
    <footer class="footer">
        <div class="container is-fullwidth has-text-centered is-size-7">
            <a href="#" @click.prevent="changeLanguage('ro')" :class="{ 'text-primary': $root.$i18n.locale === 'ro' }">Romana</a> &middot;
            <a href="#" @click.prevent="changeLanguage('en')" :class="{ 'text-primary': $root.$i18n.locale === 'en' }">English</a>
            <p>&copy; 2021 GIS Solution. {{ $t('All rights reserved.') }}</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterSection",
    methods: {
        changeLanguage(lang) {
            this.$root.$i18n.locale = lang
            this.$store.dispatch('setLanguage', lang)
        }
    }
}
</script>

<style scoped>

</style>
