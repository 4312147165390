<template>
    <header>
        <b-navbar fixed-top type="is-link">
            <template #brand>
                <b-navbar-item tag="router-link" to="/">
                    <img src="@/assets/logo.svg" alt="GIS Solution">
                </b-navbar-item>
            </template>
            <template #start>
                <b-navbar-item tag="router-link" to="/">
                    <b-icon icon="home"/>
                    {{ $t('Home') }}
                </b-navbar-item>
                <b-navbar-dropdown label="GIS">
                    <b-navbar-item tag="router-link" to="/gis/projects">
                        {{ $t('Projects') }}
                    </b-navbar-item>
                    <b-navbar-item href="#">
                        Creeaza proiect
                    </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-dropdown label="Urbanism">
                    <b-navbar-item href="#">
                        About
                    </b-navbar-item>
                    <b-navbar-item href="#">
                        Contact
                    </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-dropdown label="RENNS">
                    <b-navbar-item href="#">
                        About
                    </b-navbar-item>
                    <b-navbar-item href="#">
                        Contact
                    </b-navbar-item>
                </b-navbar-dropdown>
                <b-navbar-dropdown label="RAN">
                    <b-navbar-item href="#">
                        About
                    </b-navbar-item>
                    <b-navbar-item href="#">
                        Contact
                    </b-navbar-item>
                </b-navbar-dropdown>
            </template>

            <template #end>
                <b-navbar-item tag="div">
                    <b-select :placeholder="$t('City')" icon="globe" v-model="cityModule" @input="setCityModule">
                        <option :value="null">{{ $t('All') }}</option>
                        <option v-for="(city, i) in $store.state.common.cityModules" :value="city" :key="i">{{ city.title }}</option>
                    </b-select>
                </b-navbar-item>
                <b-navbar-item tag="div">
                    <div class="buttons">
                        <router-link to="/account" class="button is-light">
                            <b-icon icon="user"/>
                            {{ $t('Account') }}
                        </router-link>
                        <a class="button is-danger" @click="signOut">
                            <b-icon icon="lock"/>
                            {{ $t('Sign out') }}
                        </a>
                    </div>
                </b-navbar-item>
            </template>
        </b-navbar>
    </header>
</template>

<script>
export default {
    name: "MainMenu",
    data() {
        return {
            cityModule: null
        }
    },
    mounted() {
        this.cityModule = this.$store.state.selectedCityModule
    },
    methods: {
        signOut() {
            this.$store.dispatch('signOut').then(response => {
                this.$router.push('/sign-in')
            }).catch(error => {
                this.$router.push('/sign-in')
            })
        },
        setCityModule() {
            this.$store.dispatch('setCityModule', this.cityModule)
        }
    }
}
</script>

<style scoped>

</style>
