import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {config} from '../../config/config'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})

Vue.use(VueAxios, axios.create({
	baseURL: config.API_URL
}))

export default new Vuex.Store({
	state: {
		language: 'ro',
		user: null,
		redirectUrl: null,
		common: {},
		selectedCityModule: null
	},
	getters: {
		GET_LANGUAGE(state) {
			return state.language
		},
		GET_USER(state) {
			return state.user
		},
		GET_REDIRECT_URL(state) {
			return state.redirectUrl
		},
		GET_COMMON_DATA(state) {
			return state.common
		},
		GET_CITY_MODULE(state) {
			return state.selectedCityModule
		}
	},
	mutations: {
		SET_LANGUAGE(state, data) {
			state.language = data
		},
		SET_USER(state, data) {
			state.user = data
		},
		SET_REDIRECT_URL(state, data) {
			state.redirectUrl = data
		},
		SET_COMMON_DATA(state, data) {
			state.common = data
		},
		SET_CITY_MODULE(state, data) {
			state.selectedCityModule = data
		}
	},
	actions: {
		setLanguage({commit, state}, data) {
			commit('SET_LANGUAGE', data)
		},
		setCityModule({commit, state}, data) {
			commit('SET_CITY_MODULE', data)
		},
		signIn: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/login', data)
					.then(response => {
						commit('SET_USER', response.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.token
						dispatch('getCityModules')
						dispatch('getCounties')
						dispatch('getFileAccessTypes')
						dispatch('getProjectTypes')
						resolve(response)
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		signUp: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/register', data)
					.then(response => {
						resolve(response)
						commit('SET_USER', response.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.token
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		recoverPassword: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/recover-password', data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		confirmEmail: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/confirm-email', data)
					.then(response => {
						resolve(response)
						commit('SET_USER', response.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.token
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		resetPassword: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/reset-password', data)
					.then(response => {
						resolve(response)
						commit('SET_USER', response.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.token
					})
					.catch(error => {
						reject(error)
						dispatch('signOut')
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		signOut: async ({commit}) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('user/logout')
					.then(response => {
						resolve(response)
						commit('SET_USER', null)
						commit('SET_COMMON_DATA', {})
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
					.catch(error => {
						reject(error)
						commit('SET_USER', null)
						commit('SET_COMMON_DATA', {})
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		updateProfile: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.put('user', data)
					.then(response => {
						resolve(response)
						commit('SET_USER', response.data.data)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		changePassword: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.put('user/change-password', data)
					.then(response => {
						resolve(response)
						commit('SET_USER', response.data.data)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getAllProjects: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('projects/get-all', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getProject: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('projects/get', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		deleteProject: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.put('projects/delete/' + data.projectId)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getCityModules: async ({commit, dispatch, state}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('city-module/get-all', {
					params: data
				})
					.then(response => {
						commit('SET_COMMON_DATA', {
							...state.common,
							cityModules: response.data
						})
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getCounties: async ({commit, dispatch, state}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('county/get-all', {
					params: data
				})
					.then(response => {
						commit('SET_COMMON_DATA', {
							...state.common,
							counties: response.data
						})
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getFileAccessTypes: async ({commit, dispatch, state}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('file-access-type/get-all', {
					params: data
				})
					.then(response => {
						commit('SET_COMMON_DATA', {
							...state.common,
							fileAccessTypes: response.data
						})
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getProjectTypes: async ({commit, dispatch, state}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('project-type/get-all', {
					params: data
				})
					.then(response => {
						commit('SET_COMMON_DATA', {
							...state.common,
							projectTypes: response.data
						})
						resolve(response)
					})
					.catch(error => {
						if (/401|403/.test(error.response.status)) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
	},
	modules: {},
	plugins: [vuexLocal.plugin]
})
