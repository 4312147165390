import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import DefaultLayout from "@/layouts/DefaultLayout"
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout"

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
	if (!store.state.user) {
		next()
		return
	}
	next('/')
}

const ifAuthenticated = (to, from, next) => {
	if (store.state.user) {
		next()
		return
	}
	next('/sign-in')
}

const routes = [
	{
		path: '/',
		name: 'Home',
		meta: {
			layout: AuthenticatedLayout
		},
		component: () => import('@/views/Home'),
		beforeEnter: ifAuthenticated
	},
	{
		path: '/sign-in',
		name: 'Sign in',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/views/User/SignIn'),
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/sign-up',
		name: 'Sign up',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/views/User/SignUp'),
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/recover-password',
		name: 'Recover password',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/views/User/RecoverPassword'),
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/gis/projects',
		name: 'Projects',
		meta: {
			layout: AuthenticatedLayout
		},
		component: () => import('@/views/Gis/Projects'),
		beforeEnter: ifAuthenticated
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
