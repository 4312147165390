<template>
    <div class="authenticated-layout has-navbar-fixed-top is-flex is-flex-direction-column">
        <MainMenu/>
        <section id="main">
            <router-view/>
        </section>
        <FooterSection/>
        <div class="scroll-to-top" @click="$scrollToTop" v-if="pageIsScrolled">
            <b-icon icon="chevron-up"></b-icon>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import MainMenu from "@/components/layout/MainMenu";
import FooterSection from "@/components/layout/FooterSection";

export default {
    name: "AuthenticatedLayout",
    components: {FooterSection, MainMenu},
    computed: {
        ...mapGetters({
            user: 'GET_USER'
        })
    },
    data() {
        return {
            pageIsScrolled: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
        if (this.$root.$i18n.locale) {
            this.$store.dispatch('setLanguage', this.$root.$i18n.locale)
        }
        if (this.user) {
            Vue.axios.defaults.headers.common['x-access-token'] = this.user.token
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            this.pageIsScrolled = window.scrollY > 100
        }
    }
}
</script>

<style scoped>

</style>
